.MuiBottomNavigationAction-root.Mui-selected {
    color: #ffffff!important;
    font-size: 25px;
}
#mobile-bottom-navigation{
    display: none;
}

@media (max-width: 767px) {
    #navbar .header-search-field{
        margin-left: 60px;
    }
    #navbar .mobile-menu-icon{
        top: 16px!important;
    }

}

@media (max-width: 575px) {

    .btn {
        margin-bottom: 10px;
    }
    .page-item .page-link, .page-item span {
        font-size: 0.7rem;
    }
    #mobile-bottom-navigation{
        display: block;
    }

    .main-content{
        padding-bottom: 56px;
    }
    #navbarBlur{
        display: none!important;
    }
    .mobile-topbar{
        display: block!important;
    }
    .mobile-topbar .card{
        z-index: 1;
    }
    .mobile-topbar .search-area{
        background: #ffffff;
        padding: 16px;
        border-radius: 1rem;
    }
    .mobile-topbar .search-result{
        width: 100%;
        margin-left: -16px;
        border-radius: 0rem 0rem 1rem 1rem;
    }

    /*
    #navbar ul{
        display: none!important;
    }    
    #navbar .search-field{
        width: 100%!important;
    }
    .g-sidenav-show .navbar:not(.sidenav).navbar-main .navbar-collapse,
    #navbar .d-flex{
        display: block !important;
    }
    */
}